export default {
  SI002: "일치하는 로그인정보가 없습니다. 아이디나 패스워드를 확인해주세요.",
  PBP001: "이미 픽 등록을 하셨습니다. 다음 게임에 참여해주세요.",
  PBP002: "경험치가 부족합니다.",
  FMI001: "등록된 가족방이 있습니다.",
  FMI002: "가족 등록 실패",
  FMI003: "중복된 가족방 이름입니다.",
  FMJ001: "등록된 가족방이 있습니다.",
  FMJ002: "해당 가족방 가입 승인 대기중 입니다.",
  FMJ003: "가족방 가입 신청 실패",
  MII002: "프로필 변경권이 없습니다.",
  IUF001: "이미지를 확인해주세요",
  IUF002: "배너 변경권이 없습니다.",
  ATI002: "중복된 아이디입니다.",
  MIN001: "중복된 닉네임입니다.",
  MIN002: "닉네임 변경권이 없습니다.",
  MIH002: "한마디 변경권이 없습니다.",
  RQT001: "로그인정보가 만료 되었습니다.",
  MELD001: "신입은 등급판매를 할 수 없습니다.",
  MELH002: "경험치가 부족합니다.",
  CQ001: "문의하기에 실패했습니다. 카테고리를 확인해주세요.",
  CQ002: "비밀번호가 틀렸습니다. 다시 확인해주세요.",
  EXRF001: "경험치가 리필 경험치보다 많습니다.",
  CI001: "채팅방 비밀번호가 잘못되었습니다.",
  CRPJ004: "강퇴 처리 실패, 다시 시도해주세요.",
  CRIM001: "채팅방 수정에 오류가 발생했습니다. 다시 시도해주세요.",
  CRPJ005: "이미 프로젝트 시작되었습니다. 다음에 참여해주세요.",
  CRSF001: "방 채팅 목록 청소 실패. 새로고침 후 다시 시도해주세요.",
  PBP059: "등록된 프로젝트를 취소하거나 시작 후 픽을 등록해주세요.",
  BU001: "게시판 수정중에 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.",
  ATN001: "중복된 닉네임입니다.",
  UNDEFIND: "오류가 발생했습니다. 새로고침 후 다시 시도해주세요.",
  // MZE131: "블랙리스트 회원입니다.",
  MZE007: "해당 아이템을 보유하고 있지 않습니다.",
  MZE001: "로그인에 실패하였습니다. 아이디 또는 비밀번호를 확인해주세요",
  MZE002: "동일한 아이디가 존재합니다.",
  MZE004: "동일한 닉네임이 존재합니다.",
  MZE022: "보유 금액이 부족합니다.",
  MZE011: "신입은 등급판매를 할 수 없습니다.",
  MZE015: "보유 경험치가 부족합니다.",
  MZE016: "이미 구독중인 회원입니다.",
  MZE028: "캐시가 부족합니다.",
  MZE033: "댓글 삭제 실패했습니다.",
  MZE036: "가족방 이름을 입력해주세요.",
  MZE037: "중복된 가족방 이름이 존재합니다.",
  MZE038: "이미 가족방에 참여중입니다.",
  MZE040: "레벨 조건이 맞지 않습니다.",
  MZE041: "이미 가입된 가족방입니다.",
  MZE042: "가족방 가입인원 초과입니다.",
  MZE047: "포인트가 부족합니다.",
  MZE053: "중복된 가족방이름 입니다!",
  MZE065: "이미 채팅방을 가지고있습니다!",
  MZE070: "해당 방에서 차단되었습니다.",
  MZE072: "비밀번호를 확인해주세요!",
  MZE074: "보석이 부족합니다.",
  MZE082: "청소할 내용이 없습니다.",
  MZE098: "이미 추천한 방입니다.",
  MZE104: "비밀번호를 확인해주세요!",
  MZE107: "등록한 경험치를 확인해주세요.",
  MZE108: "메인볼을 확인해주세요.",
  MZE114: "이미 등록된 픽이 존재합니다.",
  MZE132: "채팅방이 없습니다.",
  MZE138: "취소 신청이 완료되지 못했습니다.",
  MZE139: "취소 신청은 하루에 한 번만 가능합니다.",
  MZE122: "경험치가 이미 충분합니다.",
  MZE135: "신분증 사본을 이미지를 입력해주세요.",
  MZE140: "기존 비밀번호가 일치하지 않습니다",
  MZE141: "비밀번호가 일치하지 않습니다",
  MZE142: "게임 픽 가능 시간이 아닙니다",
  MZE143: "이미 출석체크를 했습니다",
  MZE147: "댓글이 있는 게시글은 삭제가 불가능 합니다.",
  MZE148: "경품 교환권이 없습니다.",
  MZE149: "서비스이용에 제한된 사용자입니다. 전과기록을 확인해주세요!",
  MZE152: "채팅을 신청할 수 없는 레벨입니다.",
  MZE153: "이미 신고한 게시글 입니다!",
  MZE154: "유저가 접속중이 아닙니다.",
  MZE156: "구독가능 인원을 초과하였습니다.",
  MZE157: "비밀번호 확인하고 다시 탈퇴하기 버튼을 눌러주세요",
  MZE158: "회원 탈퇴에 실패하셨습니다.",
  MZE160: "탈퇴한 회원입니다.",
  MZE161: "추방당한 가족방입니다.",
  MZE162: "방장은 탈퇴할 수 없습니다.",
  MZE163: "사용할 수 없는 닉네임입니다.",
  MZE164: "이미 차단한 회원입니다.",
  MZE165: "차단하지 않은 회원입니다.",
  MZE166a: "상대방이 차단하여 회원정보를 불러오지 못하였습니다.",
  MZE166b: "상대방이 차단하여 채팅방을 불러오지 못하였습니다.",
  MZE167: "입금하지 않은 충전기록이 있습니다.",
  MZE171: "인증된 미니게임 토큰이 아닙니다.",
  MZE172: "답글이 존재해서 댓글을 삭제할 수 없습니다.",
  MZE173: "삭제된 채팅방입니다.",
};
