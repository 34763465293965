import Cookies from "js-cookie";
import { USER_INFO, AUTH_COOKIE } from "@/libs/constants";
import {} from "./constants";
export function setPC() {
  sessionStorage.setItem("pc", true);
}

export function getPC() {
  return sessionStorage.getItem("pc");
}
// 쿠키에 토큰 저장
export function setAuthToken(token) {
  // 로그인 전
  if (token.indexOf("TKE00") > -1) {
    return;
  }
  const authToken = token;
  //
  Cookies.set(AUTH_COOKIE.name, authToken, {
    expires: AUTH_COOKIE.expires,
    path: AUTH_COOKIE.path,
  });
}

// 쿠키에서 토큰 가져오기
export function getAuthToken() {
  return Cookies.get(AUTH_COOKIE.name, {
    path: AUTH_COOKIE.path,
    domain: AUTH_COOKIE.domain,
  });
}

// 쿠키에 유저 정보 저장
function setUserData(data) {
  const userInfo = JSON.stringify(data);
  Cookies.set(USER_INFO.name, userInfo);
}

// 쿠키에 유저 정보 가져오기
export function getUserData() {
  const userInfo = Cookies.get(USER_INFO.name);
  if (Cookies.get(USER_INFO.name)) {
    const cookieParse = JSON.parse(userInfo);
    return cookieParse;
  } else {
    return;
  }
}
// 쿠키에서 토큰 지우기
function removeAuthToken() {
  Cookies.remove(AUTH_COOKIE.name);
}

// 쿠키에서 유저 정보 지우기
function removeUserData() {
  Cookies.remove(USER_INFO.name);
}
export function setLocale() {
  // i18n 쿠키 확인
  if (!Cookies.get("locale")) {
    // 기본 언어 설정
    Cookies.set("locale", "kr");
  }
}
// 쿠키에 유저 정보 저장
export function setAuthData(data) {
  setUserData(data);
}

// 유저 관련 정보 모우 지우기
export function removeAuthData() {
  removeUserData();
  removeAuthToken();
}

// 일주일 동안 팝업 열지 않기
export function setPopupDate(index) {
  Cookies.set(`popupDate${index}`, "Y", { expires: 7 });
}

// 쿠키에 저장된 팝업 설정 불러오기
export function getPopupDate(index) {
  return Cookies.get(`popupDate${index}`);
}
